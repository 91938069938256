<template>
  <v-avatar
    :size="size"
    tile
  >
    <vue-svg-gauge
      :start-angle="-115"
      :end-angle="115"
      :value="value"
      :separator-step="1"
      :gauge-color="colors"
      xscale-interval="10"
      :inner-radius="55"
      :max="7"
    >
      <div
        class="inner-text"
      >
        {{ text ? text : value }}
      </div>
    </vue-svg-gauge>
  </v-avatar>
</template>
<script>
  import { VueSvgGauge } from 'vue-svg-gauge';

  export default {
    components: {
      VueSvgGauge,
    },
    props: {
      value: {
        type: Number,
        default: null,
      },
      text: {
        type: [Number, String],
        default: null,
      },
      size: {
        type: Number,
        default: 44,
      },
    },
    data () {
      return {
        colors: [
          { offset: 0, color: '#00814E'},
          { offset: 14.3, color: '#4CB548'},
          { offset: 28.6, color: '#C5D835'},
          { offset: 42.9, color: '#ffcc05'},
          { offset: 57.2, color: '#f79839'},
          { offset: 71.5, color: '#ec2024'},
          { offset: 85.8, color: '#ae1618'},
        ],
      }
    },
    methods: {
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  font-family: Montserrat;
  height: 100%;
  width: 100%;
  // padding-top: 1em;
  font-weight: 700;
  text-shadow: 0 0 #ccc;
  font-size: 4.8em;
  padding-top: 30%;

  span {
    max-width: 100px;
  }
}

.inner-icon {
  padding-top: 28%;
  padding-bottom: 10%;
  font-size: 6.8em;
}

.v-list-item--disabled {
  .gauge {
    opacity: 0.5;
  }
}
</style>
